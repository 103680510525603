<template>
    <div class="p-relative">
        <!-- Тулбар -->
        <div class="top-btns-bar">
            <div class="transparent-buttons">
                <v-btn small tile text @click="ExportList" :disabled="loading || total == 0">
                    <v-icon left>
                            far fa-file-chart-line
                        </v-icon>
                    {{ $t('Экспорт_списка') }}
                </v-btn>
            </div>
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </div>

        <!--Форма-->
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card class="extend-filter-common-box" flat>
                        <v-card-text>
                            <div :class="{'white-panel-items' : true, 'wpi-in-grids' : true, 'mb-0' : (!filterPanelState) }">
                                <div class="wp-row">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Территориальная_единица")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">

                                            <v-select
                                                v-model="region"
                                                :items="regions"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 mt-1 mb-1 mr-1"
                                                :placeholder="$t('Территориальная_единица')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                clearable
                                                @change="regionChange"
                                                v-tooltip.top-center='region && regions.length > 0 ? regions.find(r => r.id === region).name : ""'
                                                :readonly="regions.length == 1"
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                :placeholder="$t('Поиск')"
                                                                v-model="regionsSearch"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                            <v-select 
                                                v-if="region != null && cities.length > 0"
                                                v-model="city"
                                                :items="filtredCities"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 ma-1"
                                                :placeholder="$t('Населенный_пункт')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                @click:clear="city = null"
                                                v-tooltip.top-center='city && cities.length > 0 ? cities.find(r => r.id === city).name : ""'
                                                clearable
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                :placeholder="$t('Поиск')"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                                @input="searchCitiesList($event)"
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>

                            <template v-if="filterPanelState">
                                <v-row class="two-columns-field-in-one-box" no-gutters>                                    
                                    <v-col cols="12" sm="12" md="6">

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Список_на") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-menu 
                                                    v-model="dateMenuVisibleListOn"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="tmpExtendedFilter.listDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                            clearable
                                                            @click:clear="tmpExtendedFilter.listDate = null"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker 
                                                        v-model="tmpExtendedFilter.listDate"
                                                        @input="dateMenuVisibleListOn = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                        :min="$moment().format('yyyy-MM-DD')"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Фамилия") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.surname"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Имя") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.name"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Отчество") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.patronymic"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_рождения")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("c") }}</span>
                                                    
                                                    <v-menu 
                                                        v-model="dateMenuVisibleDateBirthFrom"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="tmpExtendedFilter.birthDateFrom | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                clearable
                                                                @click:clear="tmpExtendedFilter.birthDateFrom = null"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker 
                                                            v-model="tmpExtendedFilter.birthDateFrom"
                                                            @input="dateMenuVisibleDateBirthFrom = false"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <span>{{ $t("по") }}</span>
                                                    
                                                    <v-menu 
                                                        v-model="dateMenuVisibleDateBirthBy"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="tmpExtendedFilter.birthDateTo | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                clearable
                                                                @click:clear="tmpExtendedFilter.birthDateTo = null"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker 
                                                            v-model="tmpExtendedFilter.birthDateTo"
                                                            @input="dateMenuVisibleDateBirthBy = false"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-col>

                                    <v-col  cols="12" sm="12" md="6">
                                        <!--пустая строка для отступа-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="12">
                                                <div class="onlyReadData"></div>    
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("ИИН") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.iin"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("№_документа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.docNumber"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>

                                    </v-col>

                                </v-row>

                            </template>

                        </v-card-text>
                        <v-card-actions v-if="filterPanelState">
                            <v-spacer></v-spacer>

                            <v-btn
                                color="blue-grey"
                                text
                                depressed
                                dense
                                @click="clearExtendedFilter"
                            >
                                {{ $t("Сбросить_фильтр") }} 
                            </v-btn>

                            <v-btn
                                color="cyan"
                                text
                                depressed
                                dense
                                @click="setExtendedFilter"
                            >
                                {{ $t("Фильтровать") }}
                            </v-btn>

                        </v-card-actions>
                        <!--
                        <div class="extend-filter-btn-open" @click.stop="filterPanelState = !filterPanelState">
                            <v-icon class="mr-1">
                                {{ !filterPanelState ? 'fas fa-expand-alt' : 'fas fa-compress-alt' }}
                            </v-icon>
                            Фильтр
                        </div>
                        -->
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <!--Таблица для примера-->
        <v-data-table
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            :headers="headers"
            :options.sync="options"
            :page.sync="options.page"
            :items="entities"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
            @page-count="paginationPageCount = $event"
            @dblclick:row="(event, data) => $router.push({ name: 'Voter', params: { id: data.item.hash } })"
            :loading="loading"
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.birthDate`]="{ item }">
                {{ $moment(item.birthDate).format('DD.MM.yyyy') }}
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import store from '@/store';
import i18n from '@/i18n';
import sys from '@/services/system';
import { httpAPI } from "@/api/httpAPI";
import SearchPanel from '@/components/documents/SearchPanel.vue';
import _ from 'lodash';

const getDefaultFilter = () => {
    return {
        surname: "",
        name: "",
        patronymic: "",
        birthDateFrom: null,
        birthDateTo: null,
        iin: "",
        docNumber: "",
        listDate: null
    }
};

export default {
    name: "VotersOutDistribution",
    components: {
        SearchPanel,
    },
    asyncComputed: {
        regions: {
            async get() {
                let regions = await store.dispatch('saylau/references/fetchRegions');
                if (regions.length == 1)
                    this.region = regions[0].id;
                if (this.regionsSearch)
                    return regions.map(x => x).filter(i => sys.checkSearch(i.name, this.regionsSearch));
                else
                    return regions.map(x => x);
            },
            default: [],
            watch: ['regionsSearch']
        }
    },
    computed: {
        headers() {
            return [
                { text: i18n.t("ИИН"), value: 'hash', width: '110px', sortable: false },
                { text: i18n.t("Фамилия_Имя_Отчество"), value: 'fullName', width: '350px' },
                { text: i18n.t("Дата_рождения"), value: 'birthDate', width: '150px', sortable: false },
                { text: i18n.t("№_документа"), value: 'documentNumber', width: '150px', sortable: false },
                { text: i18n.t("Адрес"), value: 'address' },
                { text: i18n.t("РКА_дома"), value: 'rka', width: '130px', sortable: false }
            ];
        },
        filterPanelState: {
            get: function() {
                return this.$store.getters['saylau/lists/votersOutDistribution/getFilterPanelState'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/votersOutDistribution/SET_FILTER_PANEL_STATE', v);
            }
        },
        options: {
            get: function() {
                return this.$store.getters['saylau/lists/votersOutDistribution/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/votersOutDistribution/SET_TABLE_OPTION', newValue);
            }
        },
        region: {
            get: function() {
                return this.$store.getters['saylau/lists/votersOutDistribution/getRegion'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/votersOutDistribution/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/votersOutDistribution/SET_REGION', v);
                this.updateCities();
                if (!v){
                    this.entities = [];
                    this.total = 0;
                }
            }
        },
        cities: {
            get: function() {
                return this.$store.getters['saylau/lists/votersOutDistribution/getCities'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/votersOutDistribution/SET_CITIES', v);
            }
        },
        filtredCities() {
            if (this.filtrStringCities) 
                return this.cities.map(x => x).filter(i => sys.checkSearch(i.name, this.filtrStringCities));
            else 
                return this.cities.map(x => x);
        },
        city: {
            get: function() {
                return this.$store.getters['saylau/lists/votersOutDistribution/getCityId'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/votersOutDistribution/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/votersOutDistribution/SET_CITY_ID', v);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['saylau/lists/votersOutDistribution/getSearch'];
            },
            set: _.debounce(function(newValue) {
                this.$store.commit('saylau/lists/votersOutDistribution/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/votersOutDistribution/SET_SEARCH', newValue);
            }, 500)
        },
        extendedFilter() {
            return this.$store.getters['saylau/lists/votersOutDistribution/getExtendedFilter'];
        },
        filter () {
            return {
                ...this.options,
                region: this.region,
                city: this.city,
                search: this.search,
                extendedFilter: this.extendedFilter
            }
        },
        locale() {
            return this.$i18n.locale;
        }
    },
    data() {
        return {
            dateMenuVisibleListOn: false,
            dateMenuVisibleDateBirthFrom: false,
            contentDateBirthFrom: '',
            dateMenuVisibleDateBirthBy: false,
            contentDateBirthBy: '',
            
            regionsSearch: null,

            paginationPageCount: 0,

            total: 0,
            entities: [],
            loading: false,

            tmpExtendedFilter: getDefaultFilter()
        }
    },
    methods: {
        clearExtendedFilter() {
            this.tmpExtendedFilter = getDefaultFilter();
            this.$store.commit('saylau/lists/votersOutDistribution/SET_EXTENDED_FILTER', null);
        },
        setExtendedFilter() {
            this.$store.commit('saylau/lists/votersOutDistribution/SET_EXTENDED_FILTER', JSON.parse(JSON.stringify(this.tmpExtendedFilter)));
        },
        loadVoters() {
            if (this.region) {
                this.entities = [];
                this.total = 0;
                this.loading = true;

                if (this.extendedFilter)
                    this.tmpExtendedFilter = JSON.parse(JSON.stringify(this.extendedFilter));

                httpAPI
                    .get(`saylau/voters/out-distribution?filter=${this.getTableFilter()}`)
                    .then(response =>
                    {
                        this.entities = response.data.items;
                        this.total = response.data.count;
                    })
                    .finally(() => this.loading = false);
            }
            else {
                this.entities = [];
                this.total = 0;
            }
        },
        regionChange(){
            this.city = null;
            this.search = '';
            this.entities = [];
            this.total = 0;
            this.cities = [];
            this.updateCities();
        },
        updateCities(){
            if (this.region) {
                httpAPI
                    .get(`saylau/adressregister/cities/${this.region}`)
                    .then(response =>
                        {
                            this.cities = response.data;
                        })
                    .catch(() => {this.cities = [];});
            }
        },
        getTableFilter() {
            let { region, city, search, extendedFilter, page, itemsPerPage, sortBy, sortDesc } = this.filter;

            let orderBy = sortBy.length <= 0 ? null : sortBy[0];
            let descending = sortDesc.length <= 0 ? null : sortDesc[0];            

            let filter = {
                region,
                city,
                search,
                page,
                itemsPerPage,
                orderBy,
                descending
            };

            if (extendedFilter != null)
                filter['extendedFilter'] = extendedFilter;


            return JSON.stringify(filter);
        },
        ExportList(){
            sys.exportList(`saylau/voters/out-distribution?filter=${this.getTableFilter()}`, this.$notify);
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    watch: {
        filter: {
            handler () {
                if (this.region)
                    this.loadVoters();
            },
            deep: true,
        }
    },
    async beforeRouteEnter(to, from, next) {
        let filter = store.getters['saylau/lists/votersOutDistribution/getExtendedFilter'];

        next(vm => {
            if (filter)
                vm.tmpExtendedFilter = JSON.parse(JSON.stringify(filter));
        });
    },
    async beforeRouteUpdate(to, from, next) {        
        if (this.extendedFilter)
            this.tmpExtendedFilter = JSON.parse(JSON.stringify(this.extendedFilter));

        next();
    }
}
</script>